// Import mixins
@import 'src/mixins.scss';

.ant-notification {
  max-width: calc(100vw - 48px);
}

////////////////////////////////////////////////////////////////////////////////////////////
// DARK THEME STYLES
[data-vb-theme='dark'] {
  .ant-notification {
    &-notice-message {
      color: $white;
    }
    &-close-icon {
      svg {
        fill: $white;
      }
    }
  }
}
